import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import PatientTabsPage from '../views/user/patient/PatientTabsPage.vue'
import PractitionerTabsPage from "@/views/user/practitioner/PractitionerTabsPage.vue";
import {Roles} from "@/models/Roles";
import {getRole, isAuthenticated, logout} from "@/services/StorageService";
import RelativeTabsPage from "@/views/user/relative/RelativeTabsPage.vue";
import {pinia} from "@/main";
import {storeMap} from "@/stores/store/Store";
import {createAnalytics} from "@/services/rest/AnalyticsService";
import {AnalyticsCreateModel} from "@/models/AnalyticsModel";

const routes: Array<RouteRecordRaw> = [
  {                               path: '/',                                                                 redirect: '/patient/home' },
  {                               path: '/:catchAll(.*)',                                                    redirect: '/patient/home' },
  { name: 'Login',                path: '/login',                                                            component: () => import("@/views/user/LoginPage.vue") },
  { name: 'setup',                path: '/setup',                 meta: { authorize: [Roles.PATIENT] },      component: () => import('@/views/user/patient/SetupPage.vue') },
  { name: 'practitionerregister', path: '/practitioner/register',                                            component: () => import('@/views/user/practitioner/RegisterPage.vue') },
  { name: 'practitionerwelcome',  path: '/practitioner/welcome',  meta: { authorize: [Roles.PRACTITIONER] }, component: () => import('@/views/user/practitioner/PractitionerWelcomePage.vue') },
  { name: 'termsofservice',       path: '/tos',                                                              component: () => import("@/views/other/TermsOfServicePage.vue") },
  { name: 'privacy',              path: '/privacy',                                                          component: () => import("@/views/other/PrivacyPage.vue") },
  { name: 'imprint',              path: '/imprint',                                                          component: () => import("@/views/other/ImprintPage.vue") },

  {
    path: '/patient/',
    component: PatientTabsPage,
    meta: { authorize: [Roles.PATIENT] },
    children: [
      { path: '', redirect: '/patient/home' },
      { name: 'Home',                         path: 'home',                   meta: { showTabs: true, store: 'task' },    component: () => import("@/views/user/patient/PatientHomePage.vue") },
      { name: 'med',                          path: 'med',                    meta: { showTabs: true, store: 'med' },     component: () => import('@/views/med/PatientMedPage.vue') },
      { name: 'meddetail',                    path: 'med/:medid',                                                         component: () => import('@/views/med/MedDetailPage.vue') },
      { name: 'medwizzard',                   path: 'med/:userid/new',                                                    component: () => import('@/views/med/MedWizzardPage.vue') },
      { name: 'task',                         path: 'task',                   meta: { showTabs: true, store: 'task' },    component: () => import('@/views/task/TaskPage.vue') },
      { name: 'reaction',                     path: 'task/reaction',                                                  component: () => import('@/views/activity/patient/ReactionPage.vue') },
      { name: 'colon',                        path: 'task/colon',                                                     component: () => import('@/views/activity/patient/ColonPage.vue') },
      { name: 'sus',                          path: 'task/sus',                                                       component: () => import('@/views/activity/patient/SUSQuestionnairePage.vue') },
      { name: 'distress',                     path: 'task/distress',                                                  component: () => import('@/views/symptom/patient/PatientDistressPage.vue') },
      { name: 'midos',                        path: 'task/midos',                                                     component: () => import('@/views/activity/patient/MidosQuestionnairePage.vue') },
      { name: 'ipos',                         path: 'task/ipos',                                                      component: () => import('@/views/activity/patient/IposQuestionnairePage.vue') },
      { name: 'tests',                        path: 'tests',                   meta: { showTabs: true, store: 'task' },    component: () => import('@/views/task/TestPage.vue') },
      { name: 'activity',                     path: 'activity',               meta: { showTabs: true, store: 'activity'}, component: () => import('@/views/activity/patient/ActivityPage.vue') },
      { name: 'questionnaire',                path: 'activity/questionnaire',                                             component: () => import('@/views/activity/patient/QuestionnairePage.vue') },
      { name: 'info',                         path: 'info',                                                               component: () => import('@/views/info/InfoPage.vue') },
      { name: 'infoschluesselbegriffe',       path: 'info/schluesselbegriff',                                             component: () => import('@/views/info/schluesselbegriffe/InfoSchluesselbegriffePage.vue') },
      { name: 'infofaq',                      path: 'info/faq',                                                           component: () => import('@/views/info/faq/InfoFaqPage.vue') },
      { name: 'infodetail',                   path: 'info/:infoid',                                                       component: () => import('@/views/info/InfoDetailPage.vue') },
      { name: 'symptom',                      path: 'symptom',                                                            component: () => import('@/views/symptom/patient/PatientSymptomPage.vue') },
      { name: 'settings',                     path: 'settings',                                                           component: () => import('@/views/user/patient/PatientSettingsPage.vue') },
      { name: 'withingssetup',                path: 'setup/withings',                                                component: () => import('@/views/user/patient/PatientWithingsSetupPage.vue') },

    ]
  },
  {
    name: 'practitioner',
    path: '/practitioner/',
    component: PractitionerTabsPage,
    meta: { authorize: [Roles.PRACTITIONER] },
    children: [
      { name: 'default',                                     path: '',                                                                         redirect: '/practitioner/home' },
      { name: 'practitionerhome',                            path: 'home',                                           meta: { showTabs: true }, component: () => import('@/views/user/practitioner/PractitionerHomePage.vue') },
      { name: 'practitionerpatients',                        path: 'patients',                                       meta: { showTabs: true }, component: () => import('@/views/practitioner/patients/PractitionerPatientPage.vue') },
      { name: 'practitionerpatientsdetail',                  path: 'patients/:userid',                                                         component: () => import('@/views/practitioner/patients/PractitionerPatientDetailPage.vue') },
      { name: 'practitionerpatientsdetailmed',               path: 'patients/:userid/med',                                                     component: () => import('@/views/med/PractitionerMedPage.vue') },
      { name: 'practitionerpatientsdetailmeddetail',         path: 'patients/:userid/med/:medid',                                              component: () => import('@/views/med/MedDetailPage.vue') },
      { name: 'practitionerpatientsdetailmedwizzard',        path: 'patients/:userid/med/new',                                                 component: () => import('@/views/med/MedWizzardPage.vue') },
      { name: 'practitionerpatientsdetailactivity',          path: 'patients/:userid/activity',                                                component: () => import('@/views/activity/practitioner/PractitionerActivityPage.vue') },
      { name: 'practitionerpatientsdetailactivitydetail',    path: 'patients/:userid/activity/:taskid',                                        component: () => import('@/views/activity/practitioner/PractitionerActivityDetailPage.vue') },
      { name: 'practitionerpatientsdetailactivitywizzard',   path: 'patients/:userid/activity/new',                                            component: () => import('@/views/activity/practitioner/PractitionerActivityWizzardPage.vue') },
      { name: 'practitionerpatientsdetailactivityresult',    path: 'patients/:userid/activity/result/:activityname',                           component: () => import('@/views/activity/practitioner/PractitionerActivityResultPage.vue') },
      { name: 'practitionerpatientsdetailrelative',          path: 'patients/:userid/relative',                                                component: () => import('@/views/practitioner/relatives/PractitionerRelativePage.vue') },
      { name: 'practitionerpatientsdetailrelativedetail',    path: 'patients/:userid/relative/:relativeid',                                    component: () => import('@/views/practitioner/relatives/PractitionerRelativeDetailPage.vue') },
      { name: 'practitionerpatientsdetailsymptom',           path: 'patients/:userid/symptom',                                                 component: () => import('@/views/symptom/practitioner/PractitionerSymptomPage.vue') },
      { name: 'practitionerpatientsdetailsymptomdetail',     path: 'patients/:userid/symptom/:analyticsid',                                    component: () => import('@/views/symptom/practitioner/PractitionerSymptomDetailPage.vue') },
      { name: 'practitionerrelativewizzard',                 path: 'patients/:userid/relative/new',                                            component: () => import('@/views/practitioner/relatives/PractitionerRelativeWizzardPage.vue') },
      { name: 'practitionerpatientswizzard',                 path: 'patients/new',                                                             component: () => import('@/views/practitioner/patients/PractitionerPatientWizzardPage.vue') },
      { name: 'practitionersettings',                        path: 'settings',                                       meta: { showTabs: true }, component: () => import('@/views/user/practitioner/PractitionerSettingsPage.vue') },
    ]
  },
  {
    name: 'relative',
    path: '/relative/',
    component: RelativeTabsPage,
    meta: { authorize: [Roles.RELATIVE] },
    children: [
      { name: 'default',                 path: '',                                                          redirect: '/relative/med' },
      { name: 'relativemed',             path: 'med',                             meta: { showTabs: true }, component: () => import('@/views/med/RelativeMedPage.vue') },
      { name: 'relativemeddetail',       path: 'med/:medid',                                                component: () => import('@/views/med/MedDetailPage.vue') },
      { name: 'relativeactivity',        path: 'activity',                        meta: { showTabs: true }, component: () => import('@/views/activity/relative/RelativeActivityPage.vue') },
      { name: 'relativeactivityresult',  path: 'activity/result/:activityname',   meta: { showTabs: true }, component: () => import('@/views/activity/relative/RelativeActivityResultPage.vue') },
      { name: 'relativesettings',        path: 'settings',                        meta: { showTabs: true }, component: () => import('@/views/user/relative/RelativeSettingsPage.vue') },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.isReady().then(_ => {
  pinia.use(({ store }) => {
    storeMap.set(store.$id, store)
  })
})

router.beforeEach(async (to, from) => {
  const { authorize, showTabs, store }: any = to.meta;
  handleNavigationTabs(showTabs);
  loadStore(store);

  const role = await getRole();
  if (authorize) {

    const userIsAuthenticated = await isAuthenticated();
    if (!userIsAuthenticated) return { name: 'Login' };
    if (authorize.length && !authorize.includes(role)) return { name: 'Login' };
  }
})

// Register pageviews
router.afterEach((to, from) => {
  console.log("afterEach");

  const analytics = AnalyticsCreateModel.createAnalyticsResultFromValues(
    "pageview",
    "change",
    [to.fullPath]
  );
  createAnalytics(analytics).catch(error => console.log(error))
})


function handleNavigationTabs(showTabs: any) {
  if (showTabs) {
    window.dispatchEvent(new CustomEvent('digipal:navigation:show'));
  } else {
    window.dispatchEvent(new CustomEvent('digipal:navigation:hide'));
  }
}

function loadStore(name: string) {
  const store: any = storeMap.get(name)
  if (store !== undefined) {
    store.load()
  }
}

const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  const [resource, config ] = args;
  const response = await originalFetch(resource, config);
  if (response.status == 401 || response.status == 403) {
    await logout()
  }
  return response;
};

export default router
