import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { IonTabBar, IonTabs, IonPage, IonRouterOutlet } from '@ionic/vue';
import {onMounted, ref} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppTabBar',
  setup(__props) {

const isHidden = ref(false)

onMounted(() => {
  addNavigationListeners();
})

function addNavigationListeners() {
  window.addEventListener('digipal:navigation:hide', () => {
    isHidden.value = true;
  });
  window.addEventListener('digipal:navigation:show', () => {
    isHidden.value = false;
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonTabs), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRouterOutlet)),
          _createVNode(_unref(IonTabBar), {
            slot: "bottom",
            hidden: isHidden.value
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 8, ["hidden"])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}
}

})