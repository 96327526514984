import {environment} from "@/environment";
import {AnalyticsCreateModel, AnalyticsResultModel} from "@/models/AnalyticsModel";

export async function getAnalyticsFor(name: string, activity: string): Promise<Array<AnalyticsResultModel>> {
    const path = `${environment.analyticsAddress}?name=${name}&activity=${activity}`
    return await fetch(path, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        mode: 'cors',
    }).then(res => res.json())
        .then(data => AnalyticsResultModel.create(data))
        .catch(error => error)
}

export async function getAnalyticsById(id: string): Promise<AnalyticsResultModel> {
    const path = `${environment.analyticsAddress}/${id}`
    return await fetch(path, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        mode: 'cors',
    }).then(res => res.json())
        .then(data => AnalyticsResultModel.createOne(data))
        .catch(error => error)
}

export async function createAnalytics(analytics: AnalyticsCreateModel) {
    const url = `${environment.analyticsAddress}`
    return await fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({analytics: analytics}),
        credentials: 'include',
        mode: 'cors',
    })
        .then(data => data)
        .catch(error => {
            console.log(`Error creating Analytics: ${error}`);
        })
}