import {ValidatedFormPage} from "@/models/ValidatedFormPage";

export class AnalyticsResultModel {
    constructor(
        readonly id: string = "",
        readonly datetime: string = "",
        readonly type: string = "",
        readonly name: string = "",
        readonly details: Array<AnalyticsSingleResult> = [],
    ) { }

    static create(data: any): [AnalyticsResultModel] {
        return data.map((d: any) => new AnalyticsResultModel(d.id, d.datetime, d.type, d.name, d.details));
    }

    static createOne(data: any): AnalyticsResultModel {
        return new AnalyticsResultModel(data.id, data.datetime, data.type, data.name, data.details);
    }

    static empty(): AnalyticsResultModel {
        return new AnalyticsResultModel("", "", "", "", []);
    }
}

export class AnalyticsCreateModel {
    constructor(
        readonly type: string = "",
        readonly name: string = "",
        readonly details: Array<AnalyticsSingleResult>
    ) { }

    static createFromValidatedForm(type: string, name: string, value: Array<ValidatedFormPage>): AnalyticsCreateModel {
        const allSingleResult = value.map(v => new AnalyticsSingleResult(v.inputs[0].id, v.inputs[0].value))
        return new AnalyticsCreateModel(type, name, allSingleResult)
    }

    static createAnalyticsResultFromValues(type: string, name: string, value: Array<string>) {
        const allSingleResult = value.map((v, idx) => new AnalyticsSingleResult(idx.toString(), v))
        return new AnalyticsCreateModel(type, name, allSingleResult)
    }
}

export class AnalyticsSingleResult {
    constructor(
        readonly id: string = "",
        readonly value: string = "",
    ) { }
}