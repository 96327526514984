export const environment = {
    apiBase: process.env.VUE_APP_API_URL + '/api',
    userAddress: process.env.VUE_APP_API_URL + '/api/users-custom',
    practitionerAddress: process.env.VUE_APP_API_URL + '/api/practitioner',
    patientAddress: process.env.VUE_APP_API_URL + '/api/patient',
    relativeAddress: process.env.VUE_APP_API_URL + '/api/relative',
    fileAddress: process.env.VUE_APP_API_URL + '/api/files',
    medAddress: process.env.VUE_APP_API_URL + '/api/med',
    taskAddress: process.env.VUE_APP_API_URL + '/api/task',
    activityAddress: process.env.VUE_APP_API_URL + '/api/activity-custom',
    questionnaireAddress: process.env.VUE_APP_API_URL + '/api/questionnaire-custom',
    analyticsAddress: process.env.VUE_APP_API_URL + '/api/analytics-custom',
    infoAddress: process.env.VUE_APP_API_URL + '/api/info'
}