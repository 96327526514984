import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { IonTabButton, IonLabel, IonIcon } from '@ionic/vue';
import AppTabBar from "@/components/container/AppTabBar.vue";
import {createOutline, medkitOutline, settingsOutline} from "ionicons/icons";

export default /*@__PURE__*/_defineComponent({
  __name: 'RelativeTabsPage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppTabBar, null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonTabButton), {
        tab: "patients",
        href: "/relative/med"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonIcon), { icon: _unref(medkitOutline) }, null, 8, ["icon"]),
          _createVNode(_unref(IonLabel), null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Medikamente")
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonTabButton), {
        tab: "activity",
        href: "/relative/activity"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonIcon), { icon: _unref(createOutline) }, null, 8, ["icon"]),
          _createVNode(_unref(IonLabel), null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Aktivitäten")
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonTabButton), {
        tab: "settings",
        href: "/relative/settings"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonIcon), { icon: _unref(settingsOutline) }, null, 8, ["icon"]),
          _createVNode(_unref(IonLabel), null, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Einstellungen")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})